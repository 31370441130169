  export class apiUrl {


    // region login
    public static get Login(): string { return "Authenticate"; }
    public static get CheckToken(): string { return "Authenticate/CheckToken"; }



    public static get GetStoreProducts(): string { return "InvRawProducts/GetStoreProducts/"; }

    // end
    // region dashboard
    public static get DashboardDBasicData(): string { return "Dashboard/GetBasicData"; }
    public static get GetRawProductMinStockAlert(): string { return "Dashboard/GetRawProductMinStockAlert"; }


    public static get GetUserDetails(): string { return "Users/GetUserDetails"; }
    
    // end





    // region brand
    public static get InvBrandsGetAllForDropwDown(): string { return "InvBrands/GetdlBrands"; }  //options
    public static get InvBrandsGetAll(): string { return "InvBrands/GetStoreBrands"; }   //options
    public static get InvBrandsGetById(): string { return "InvBrands"; }   //options
    public static get InvBrandsSave(): string { return "InvBrands"; }   //post
    public static get InvBrandsUpdate(): string { return "InvBrands"; }       //put
    public static get InvBrandsDisable(): string { return "InvBrands/DisableBrand"; } //put
    public static get InvBrandsDelete(): string { return "InvBrands"; }   
    // end


    // region categories
    public static get InvCategoriesGetAllForDropwDown(): string { return "InvCategories/GetdlCategories"; }  //options
    public static get InvCategoriesGetAll(): string { return "InvCategories/GetCompanyCategories"; }   //options
    public static get InvCategoriesGetById(): string { return "InvCategories"; }   //options
    public static get InvCategoriesSave(): string { return "InvCategories"; }   //post
    public static get InvCategoriesUpdate(): string { return "InvCategories"; }       //put
    public static get InvCategoriesDisable(): string { return "InvCategories/DisableCategory"; } //put
    public static get InvCategoriesDelete(): string { return "InvCategories"; }   
    // end


    // region subcategories
    public static get InvSubCategoriesGetAllForDropwDown(): string { return "InvSubCategories/GetdlSubCategories"; }  //options
    public static get InvSubCategoriesGetAll(): string { return "InvSubCategories/GetCompanySubCategories"; }   //options
    public static get InvSubCategoriesGetById(): string { return "InvSubCategories"; }   //options
    public static get InvSubCategoriesSave(): string { return "InvSubCategories"; }   //post
    public static get InvSubCategoriesUpdate(): string { return "InvSubCategories"; }       //put
    public static get InvSubCategoriesDisable(): string { return "InvSubCategories/DisableSubCategory"; } //put
    public static get InvSubCategoriesDelete(): string { return "InvSubCategories"; }   
    // end


    // region supplier
    public static get InvSuppliersGetAllForDropwDown(): string { return "InvSuppliers/GetdlSuppliers"; }  //options
    public static get InvSuppliersGetAll(): string { return "InvSuppliers/GetSuppliers"; }   //options
    public static get InvSuppliersGetById(): string { return "InvSuppliers"; }   //options
    public static get InvSuppliersSave(): string { return "InvSuppliers"; }   //post
    public static get InvSuppliersUpdate(): string { return "InvSuppliers"; }       //put
    public static get InvSuppliersDisable(): string { return "InvSuppliers/DisableSupplier"; } //put
    public static get InvSuppliersDelete(): string { return "InvSuppliers"; }   
    // end


     // region supplier
     public static get InvCustomersGetAllForDropwDown(): string { return "InvCustomers/GetdlCustomers"; }  //options
     public static get InvCustomersGetAll(): string { return "InvCustomers/GetCustomers"; }   //options
     public static get InvCustomersGetById(): string { return "InvCustomers"; }   //options
     public static get InvCustomersSave(): string { return "InvCustomers"; }   //post
     public static get InvCustomersUpdate(): string { return "InvCustomers"; }       //put
     public static get InvCustomersDisable(): string { return "InvCustomers/DisableCustomer"; } //put
     public static get InvCustomersDelete(): string { return "InvCustomers"; }   
     // end

    //region company Products
    public static get InvCompanyRawProductsList(): string { return "InvCompanyRawProducts/GetCompanyRawProducts"; } //get 
    public static get InvCompanyRawProducts(): string { return "InvCompanyRawProducts"; } //get
    public static get InvCompanyRawProductsSave(): string { return "InvCompanyRawProducts"; } //post
    public static get InvCompanyRawProductById(): string { return "InvCompanyRawProducts"; } //options
    public static get InvCompanyRawProductUpdate(): string { return "InvCompanyRawProducts"; } //put
    public static get InvCompanyRawProductDelete(): string { return "InvCompanyRawProducts"; } 
    //end

    //region Company recipe
    public static get InvCompanyAllRecipedl(): string { return "InvCompanyProductRecipes/GetdlAllCompanyRecipes"; } //get
    public static get InvCompanyRecipes(): string { return "InvCompanyProductRecipes/GetCompanyRecipes"; } //get
    public static get InvCompanyRawProductsdl(): string { return "InvCompanyRawProducts/GetCompanyRawProducts"; } //get company products
    public static get InvCompanyRecipeById(): string { return "InvCompanyProductRecipes"; } //post
    public static get InvCompanyRecipeSave(): string { return "InvCompanyProductRecipes"; } //post
    public static get InvCompanyRecipeUpdate(): string { return "InvCompanyProductRecipes"; } //put
    public static get InvCompanyRecipeDelete(): string { return "InvCompanyProductRecipes"; } 
    public static get InvCompanyRecipeMapping(): string { return "InvCompanyProductRecipes/GetCompanyRawProductRecipeMapping"; } //get Recipe mapping
    public static get InvCompanyRecipeMappingInsert(): string { return "InvCompanyProductRecipes/CompanyRawProductRecipeMappingInsert"; }
    
    
    //end
   
    // region products
    public static get InvRawProductsGetAllForDropwDown(): string { return "InvRawProducts/GetdlRawProductsbyStore"; }  //options
    public static get InvRawProductsGetAll(): string { return "InvRawProducts/GetStoreProducts"; }   //options
    public static get InvRawProductsGetById(): string { return "InvRawProducts"; }   //options
    public static get InvRawProductsSave(): string { return "InvRawProducts"; }   //post
    public static get InvRawProductsUpdate(): string { return "InvRawProducts"; }       //put
    public static get InvRawProductsDisable(): string { return "InvRawProducts/DisableProduct"; } //put
    public static get InvRawProductsDelete(): string { return "InvRawProducts"; }   


    public static get MeasuringUnitGetAllForDropDown (): string { return "InvRawProducts/GetdlMeasuringUnit"; }   
    public static get GetAllUtilizationTypeForDropDown (): string { return "InvStocks/GetdlUtilizationType"; }   
 

    public static get GetStoreStockReport (): string { return "InvReports/GetStoreStockReport"; }   

    public static get GetStoreRecipeStockReport (): string { return "InvReports/GetStoreRecipeStockReport"; }   

    public static get GetStorePurchaseStockReport (): string { return "InvReports/GetStorePurchaseStockReport"; }   
    public static get GetStoreProductPurchaseStockReport (): string { return "InvReports/GetStoreProductPurchaseStockReport"; }   
    public static get GetStorePurchaseStocks (): string { return "InvPurchaseStocks/GetStorePurchaseStocks"; }   


    public static get GetStockReceiptReport (): string { return "InvReports/GetStockReceiptReport"; }   

    public static get GetPurchaseRequestReport (): string { return "InvReports/GetPurchaseRequestReport"; }   
    public static get GetQuotationRequestReport (): string { return "InvReports/GetQuotationRequestReport"; }   
    public static get GetPurchaseOrderReport (): string { return "InvReports/GetPurchaseOrderReport"; }   


    public static get GetStockTransferReport (): string { return "InvReports/GetStockTransferReport"; }   
    public static get GetStockTransferItemReport (): string { return "InvReports/GetStockTransferItemReport"; }   


    public static get GetRawProductLogReport (): string { return "InvReports/GetRawProductLogReport"; }   
    public static get GetProductRecipeLogReport (): string { return "InvReports/GetProductRecipeLogReport"; }  
    
    public static get GetProfitReport ():string {return "InvReports/GetProfitReport";}


    public static get GetStorePurchaseStocksByPRoduct (): string { return "InvPurchaseStocks/GetStorePurchaseStocks/"; }   
    public static get GetStoreStockReportDetailed (): string { return "InvReports/GetStoreStockReportDetailed/"; }   



    public static get GetStoreStocks (): string { return "InvStocks/GetStoreStocks"; }   
    
    public static get AddStoreStock (): string { return "InvStocks/AddStoreStock"; }   


    public static get AddStoreStockReceipt (): string { return "InvStockReceipts/AddStoreStockReceipt"; }   


    public static get InvPurchaseStocksSave (): string { return "InvPurchaseStocks"; }   
    public static get InvPurchaseStocksUpdate (): string { return "InvPurchaseStocks"; }   
    public static get GetInvPurchaseStocksById (): string { return "InvPurchaseStocks"; }   
    public static get GetStorePurchaseStockstoMove (): string { return "InvPurchaseStocks/GetStorePurchaseStockstoMove"; } 
    public static get DeletePurchaseStock (): string { return "InvPurchaseStocks/DeletePurchaseStock"; }  
    public static get DeletePurchaseStockDetails (): string { return "InvPurchaseStocks/DeletePurchaseStockDetails"; }  
    public static get GetUnPaidStorePurchaseStocks (): string { return "InvPurchaseStocks/GetUnPaidStorePurchaseStocks"; }   

    public static get GetQuotationRequestbyStore (): string { return "InvQuotationRequests/GetQuotationRequestbyStore"; }   
    public static get GetdlStoreWithPurchaseRequest (): string { return "InvQuotationRequests/GetdlStoreWithPurchaseRequest"; }   



    public static get GetUnMovedPurchaseStock (): string { return "InvStockReceipts/GetUnMovedPurchaseStock"; }   


    public static get GetUnMovedPurchaseStockbyId (): string { return "InvStockReceipts/GetUnMovedPurchaseStockbyId"; }   


    

    public static get InvStockReceiptsSave (): string { return "InvStockReceipts"; }   
    public static get InvStockReceiptsUpdate (): string { return "InvStockReceipts"; }   
    public static get GetInvStockReceiptsById (): string { return "InvStockReceipts"; }   
    public static get GetStoretockReceiptstoMove (): string { return "InvStockReceipts/GetStoretockReceiptstoMove"; }   
    public static get DeletPStoretockReceiptsDetails (): string { return "InvStockReceipts/DeletPurchaseOrderDetails"; }  

    public static get InvPurchaseOrdersSave (): string { return "InvPurchaseOrders"; }   
    public static get InvPurchaseOrdersUpdate (): string { return "InvPurchaseOrders"; }   
    public static get GetInvPurchaseOrdersById (): string { return "InvPurchaseOrders"; }   
    public static get GetStorePurchaseOrderstoMove (): string { return "InvPurchaseOrders/GetStorePurchaseOrderstoMove"; }   
    public static get DeletPurchaseOrderDetails (): string { return "InvPurchaseOrders/DeletPurchaseOrderDetails"; }  
   
   
    public static get InvSalesOrdersSave (): string { return "InvSalesOrders"; }   
    public static get InvSalesOrdersUpdate (): string { return "InvSalesOrders"; }   
    public static get GetInvSalesOrdersById (): string { return "InvSalesOrders"; }   
    public static get GetStoreSalesOrderstoMove (): string { return "InvSalesOrders/GetStoreSalesOrderstoMove"; }   
    public static get DeletSalesOrderDetails (): string { return "InvSalesOrders/DeleteSalesOrder"; }  
    public static get ConfirmSalesOrder (): string { return "InvSalesOrders/ConfirmSalesOrder"; }  
   
    public static get InvPurchaseRequestsSave (): string { return "InvPurchaseRequests"; }   
    public static get InvPurchaseRequestsUpdate (): string { return "InvPurchaseRequests"; }   
    public static get GetInvPurchaseRequestsById (): string { return "InvPurchaseRequests"; }   
    public static get GetUnApprovedPurchaseRequests (): string { return "InvPurchaseRequests/GetUnApprovedPurchaseRequest"; }   
    public static get GetApprovedPurchaseRequests (): string { return "InvPurchaseRequests/GetApprovedPurchaseRequest"; }   
    public static get ApprovePurchaseRequests (): string { return "InvPurchaseRequests/ApprovePurchaseRequest"; }   
   

    public static get InvQuotationRequestsSave (): string { return "InvQuotationRequests"; }   
    public static get InvQuotationRequestsUpdate (): string { return "InvQuotationRequests"; }   
    public static get GetInvQuotationRequestsById (): string { return "InvQuotationRequests"; }   
    public static get GetInvQuotationRequestsByIdForPurchaseOrder (): string { return "InvQuotationRequests/GetStoreQuotationRequest"; }   
    public static get GetUnClosedQuotationRequests (): string { return "InvQuotationRequests/GetUnClosedQuotationRequests"; }   
    // public static get GetApprovedPurchaseRequests (): string { return "InvQuotationRequests/GetApprovedPurchaseRequest"; }   
    // public static get ApprovePurchaseRequests (): string { return "InvQuotationRequests/ApprovePurchaseRequest"; }   


    public static get GetPurchaseRequestsDetails (): string { return "InvPurchaseRequests/GetPurchaseRequestsDetails"; }   

    public static get GetApprovedConsolidatePurchaseRequest (): string { return "InvPurchaseRequests/GetApprovedConsolidatePurchaseRequest"; }   

    // public static get DeletPurchaseOrderDetails (): string { return "InvPurchaseRequests/DeletPurchaseOrderDetails"; }  

    
    public static get InvStockRequestsSave (): string { return "InvStockRequests"; }   
    public static get InvStockRequestsUpdate (): string { return "InvStockRequests"; }   
    public static get GetInvStockRequestsById (): string { return "InvStockRequests"; }   
    public static get DeleteInvStockRequests (): string { return "InvStockRequests"; }   

    public static get GetUnApprovedStoreStockRequests (): string { return "InvStockRequests/GetUnApprovedStoreStockRequests"; }   
    public static get GetApprovedStoreStockRequests (): string { return "InvStockRequests/GetApprovedStoreStockRequests"; }   
    public static get ApproveStockRequest (): string { return "InvStockRequests/ApproveStockRequest"; }   



    // public static get GetStorePurchaseStockstoMove (): string { return "InvStockRequests/GetStorePurchaseStockstoMove"; }   
    // public static get DeletePurchaseStockDetails (): string { return "InvStockRequests/DeletePurchaseStockDetails"; }  
    // public static get GetUnPaidStorePurchaseStocks (): string { return "InvStockRequests/GetUnPaidStorePurchaseStocks"; }   
     


     public static get InvStockTransfersSave (): string { return "InvStockTransfers"; }   
     public static get InvStockTransfersUpdate (): string { return "InvStockTransfers"; }   
     public static get GetInvStockTransfersById (): string { return "InvStockTransfers"; }   
     public static get DeleteInvStockTransfers (): string { return "InvStockTransfers"; }   
   

      public static get GetUnConfirmedStoreStockTransfers (): string { return "InvStockTransfers/GetUnConfirmedStoreStockTransfers"; }   
    public static get GetConfirmedStoreStockTransfers (): string { return "InvStockTransfers/GetConfirmedStoreStockTransfers"; }   
    public static get GetNonReceivedStockTransfers (): string { return "InvStockTransfers/GetNonReceivedStoreStockTransfers"; }   
    public static get ConfirmStockTransfer (): string { return "InvStockTransfers/ConfirmStockTransfer"; }   
    public static get ReceivedStockTransfer (): string { return "InvStockTransfers/ReceivedStockTransfer"; }   


    public static get UpdateStorePurchaseStockPayment (): string { return "InvPurchaseStocks/UpdateStorePurchaseStockPayment"; }   

    public static get GetStoresForDropDown (): string { return "Common/GetdlStore"; }   



    public static get GetStoreProductRecipeStock (): string { return "InvProductRecipeStocks/GetStoreProductRecipeStock/"; }   


    // public static get GetStoreAvaliableStocks (): string { return "InvStocks/GetStoreAvaliableStocks/"; }   

    public static get UpdateUnSettledProductRecipeLog (): string { return "InvProductRecipes/UpdateUnSettledProductRecipeLog"; }   
    public static get UpdateUnSettledRawProductLog (): string { return "InvRawProducts/UpdateUnSettledRawProductLog"; }   
    public static get StoreStockExcelUpload (): string { return "InvStocks/StoreStockExcelUpload"; }   

    // public static get GetStorePurchaseStocks (): string { return "InvPurchaseStocks/GetStorePurchaseStocks"; }   

    public static get GetAllPaymentModesForDropDown(): string { return "InvPurchaseStocks/GetPaymentModes"; }  //options


    public static get getAllWareHouseStores(): string { return "Common/GetdlWarehouseStore/"; }  //options


    public static get GetExpiredProductRecipeSales(): string { return "InvProductRecipeStocks/GetExpiredProductRecipeSales"; }  //options
    public static get GetCompanyExpiredProductRecipeSales(): string { return "InvProductRecipeStocks/GetCompanyExpiredProductRecipeSales"; }  //options
    // public static get GetConsolidateExpiredStocks(): string { return "InvStocks/GetConsolidateExpiredStocks"; }  //options

    public static get GetCompanyConsolidateExpiredStocks(): string { return "InvStocks/GetCompanyConsolidateExpiredStocks"; }  //options
    public static get GetConsolidateExpiredStocks(): string { return "InvStocks/GetConsolidateExpiredStocks"; }  //options
    public static get GetStoreExpiredStockReportDetailed(): string { return "InvReports/GetStoreExpiredStockReportDetailed/"; }  //options


    public static get GetUnMovedStockReceipts(): string { return "InvStockReceipts/GetUnMovedStockReceipts"; }  //options

    public static get GetCashierStoreExpenses() :string {return 'StoreExpenses/GetStoreExpenses'} //get
    public static get SaveStoreExpenses() :string {return 'StoreExpenses'}//post
    public static get GetStoreExpenses() :string {return 'StoreExpenses/'}//get
    public static get UpdateStoreExpenses() :string {return 'StoreExpenses/'}//post


    // end

    public static get StoreStockAdjustment(): string { return "InvStocks/StoreStockAdjustment"; }  //options


    public static get StoreRecipeStockAdjustment(): string { return "InvProductRecipeStocks/StoreRecipeStockAdjustment"; }  //options

    

   
    public static get GetStoreAvaliableStocks (): string { return "InvStocks/GetStoreAvaliableStocks"; }   
    public static get GetStoreRecipeStock (): string { return "InvProductRecipeStocks/GetStoreRecipeStock"; }   

    public static get GetConsolidateAvaliableStocks (): string { return "InvStocks/GetConsolidateAvaliableStocks";} //get available stocks consolidated


    public static get GetCompanyConsolidateAvaliableStocks (): string { return "InvStocks/GetCompanyConsolidateAvaliableStocks";} //get available stocks consolidated
    public static get GetCompanyProductRecipeSales (): string { return "InvProductRecipeStocks/GetCompanyProductRecipeSales";} //get available stocks consolidated


    //public static get GetUnSettledProductRecipeLog (): string { return "InvProductRecipes/GetUnSettledProductRecipeLog/0";} //get available stocks consolidated

    public static get GetUnSettledProductRecipeLog (): string { return "InvProductRecipes/GetUnSettledProductRecipeLog?productRecipeId=0&storeId=";} //get available stocks consolidated


    //public static get GetUnSettledRawProductLog ():  string { return "InvRawProducts/GetUnSettledRawProductLog/0";} //get available stocks consolidated

    public static get GetUnSettledRawProductLog (): string { return "InvRawProducts/GetUnSettledRawProductLog?rawProductId=0&storeId=";} //get available stocks consolidated
    public static get GetProductRecipeSales (): string { return "InvProductRecipeStocks/GetProductRecipeSales";} //get available stocks consolidated


    public static get GetApprovedConsolidateStockRequests (): string { return "InvStockRequests/GetApprovedConsolidateStockRequests";} //get available stocks consolidated


    //measuring units

    public static get MeasuringUnits():string { return "InvMeasuringUnits"} //get, add,update,delete
    public static get DisableMeasuringUnits():string { return "InvMeasuringUnits/DisableMeasuringUnit";} //disable

    public static get GetTransactionReport (): string { return "InvReports/GetTransactionReport"; }   

   
    public static get GetTransactionDetailsReport (): string { return "InvReports/GetTransactionDetailsReport"; }   

    //Product Mapping
    public static get DisposableProducts():string {return "InvRawProducts/GetdlDisposableProducts";} // get disposable products drop down
    public static get GetMenuProductsMapping():string {return "InvRawProducts/GetMenuProductsMapping";} // get disposable products drop down///api url constants
    public static get InsertMenuProductsMapping():string {return "InvRawProducts/InsertMenuProductsMapping";} //post the mapped produts
  
  
  
  

     // region products

     public static get GetAllCompanyRecipesForDropDown(): string { return "InvCompanyProductRecipes/GetdlAllCompanyRecipes/"; }  //options
     public static get GetAllCompanyRawProductsForDropDown(): string { return "InvCompanyRawProducts/GetdlCompanyRawProducts/"; }  //options


     public static get InvProductRecipesGetAllForDropwDown(): string { return "InvProductRecipes/GetdlRecipes/"; }  //options
     public static get InvProductRecipesGetAllForDropwDownForBoth(): string { return "InvProductRecipes/GetdlAllRecipes/"; }  //options

     public static get InvProductRecipesGetAll(): string { return "InvProductRecipes/GetStoreRecipes"; }   //options
     public static get InvProductRecipesGetById(): string { return "InvProductRecipes"; }   //options
     public static get InvProductRecipesSave(): string { return "InvProductRecipes"; }   //post
     public static get InvProductRecipesUpdate(): string { return "InvProductRecipes"; }       //put
     public static get InvProductRecipesDisable(): string { return "InvProductRecipes/DisableProduct"; } //put
     public static get InvProductRecipesDelete(): string { return "InvProductRecipes"; }   


     public static get InvProductRecipesMapping(): string { return "InvProductRecipes/GetRawProductRecipeMapping"; }   //
     public static get RawProductRecipeMappingInsert(): string { return "InvProductRecipes/RawProductRecipeMappingInsert"; }   //

  

     public static get RawRecipeRawProductMappingInsert(): string { return "InvRawProducts/RawRecipeRawProductMappingInsert"; }   //
     public static get GetRawRecipeRawProductMapping(): string { return "InvRawProducts/GetRawRecipeRawProductMapping"; }   //
     public static get GetdlRawProductsForProduction(): string { return "InvRawProducts/GetdlRawProductsForProduction"; }   //
     public static get GetdlRawProductsForSubRecipe(): string { return "InvRawProducts/GetdlRecipeRawProducts"; }   //



     public static get GetdlCompanyRawProductsForProduction(): string { return "InvRawProducts/GetdlCompanyRawProductsForProduction"; }   //

     public static get MenuRecipeMappingInsert(): string { return "InvProductRecipes/ProductRecipeMappingInsert"; }   //
     public static get GetMenuRecipeMapping(): string { return "InvProductRecipes/GetProductRecipeMapping"; }   //



     public static get GetProductRawProductMapping(): string { return "InvRawProducts/GetProductRawProductMapping"; }   //
     public static get ProductRawProductMappingInsert(): string { return "InvRawProducts/ProductRawProductMappingInsert"; }   //


     public static get ComboMenuRecipeMappingInsert(): string { return "InvProductRecipes/ComboProductRecipeMappingInsert"; }   //
     public static get GetComboMenuRecipeMapping(): string { return "InvProductRecipes/GetComboProductRecipeMapping"; }   //


     public static get GetMenuProductsByMenuCategory(): string { return "InvProductRecipes/GetProducts"; }   //

     public static get GetMenuCategories(): string { return "InvProductRecipes/GetdlMenuCategory/"; }   //



     public static get GetComboItemsForDropDown(): string { return "InvProductRecipes/GetdlComboProduct/"; }   //



     public static get GetProductRecipeStock(): string { return "InvProductRecipes/GetProductRecipeStock"; }   //
     public static get AddProductRecipeStock(): string { return "InvProductRecipes/AddProductRecipeStock"; }   //


     public static get AddRawProductStock(): string { return "InvRawProducts/AddRawProductStock"; }   //
     public static get GetRawProductStock(): string { return "InvRawProducts/GetRawProductStock"; }   //


     public static get GetdlSellableProducts(): string { return "InvRawProducts/GetdlSellableProducts"; }   //


     //settings

     public static get GetInvStoreSettings(): string { return "InvStoreSettings/GetStoreSettings"; }   //
     public static get SaveInvStoreSettings(): string { return "InvStoreSettings"; }   //



     //region transfertostore

     public static get GetdlStore(): string { return "Common/GetdlStore"; }   //commom

     public static get moveProductsAndRecipes(): string { return "Common/MoveCompanyProductsNRecipes"; }

     //region supplier payments

     public static get CompanySupplierPayments():string { return "InvPurchaseStocks/GetCompanySupplierPayableAmount";}
     public static get CompanyPurchaseStocksUnpaid():string { return "InvPurchaseStocks/GetUnPaidCompanyPurchaseStocks";}
     public static get SupplierConsolidatePayment(): string {return "InvPurchaseStocks/PurchaseStockMultiplePayment";}
     public static get SupplierStatementReport():string{return "InvReports/GetSupplierStatement";}

     public static get StorePurchaseStocksdl():string {return "InvPurchaseStocks/GetdlStorePurchaseStocks";}

     public static get GetPurchaseStockForReturn():string {return "InvPurchaseStocks/GetPurchaseStockForReturn";}

     //region stock returns

     public static get InvPurchaseReturns():string {return "InvPurchaseReturns";}
     public static get InvPurchaseReturnsById():string {return "InvPurchaseReturns";}
     public static get DeletePurchaseReturns():string {return "InvPurchaseReturns/DeletePurchaseReturn";}
     public static get ConfirmPurchaseReturns():string {return "InvPurchaseReturns/ConfirmPurchaseReturn";}
     public static get GetUnConfirmedStorePurchaseReturns():string {return "InvPurchaseReturns/GetUnConfirmedStorePurchaseReturns";}

     //region customer payments

     public static get CompanyCustomerPayments():string{return "InvSalesOrders/GetCompanyCustomerPayableAmount"}
     public static get CompanySalesOrdersUnpaid():string { return "InvSalesOrders/GetUnPaidCompanySalesOrders";}
     public static get CustomerConsolidatePayment(): string {return "InvSalesOrders/SalesStockMultiplePayment";}

     //region reports

     public static get GetProductRecipeConsumptionReport():string {return "InvReports/GetProductRecipeConsumptionReport";}
     public static get GetRawProductConsumptionReport():string {return "InvReports/GetRawProductConsumptionReport";}

     public static get GetPurchaseReturnReport():string {return "InvReports/GetPurchaseReturnReport";}
     public static get GetSalesOrderReport():string {return "InvReports/GetSalesOrderReport";}

     public static get GetStockInOutReport():string {return "InvReports/GetStockInOutReport";}
  
    }
